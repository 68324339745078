import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";
import PropTypes from "prop-types";
import Box from "reusecore/src/elements/Box";
import Text from "reusecore/src/elements/Text";
import Heading from "reusecore/src/elements/Heading";
import Logo from "reusecore/src/elements/UI/Logo";
import VisualEyesLogo from "../../components/VisualEyesLogo";
import Container from "../../components//Container";
import FooterWrapper, {
  Copyrights,
  LogoWrapper,
  ListHeader,
  List,
  ListItem,
} from "./footer.style";
import data from "./data";
import { fbTrack } from "../../utils/analytics";
import CTASection from "../CTASection";
import { APP_SIGN_UP_URL } from "../../utils/constants";
import FooterSocial from "./FooterSocial";

const Anchor = styled.a`
  &,
  &:link,
  &:active,
  &​:visited {
    font-weight: 500;
    color: #212121;
    text-decoration: underline;
  }

  &:hover,
  & > p:hover {
    color: #3e21de;
  }

  font-size: 16px;
  margin-bottom: 10px;
`;

const Label = styled.span`
  background-color: transparent;
  border: 2px solid #3e21de;
  color: #3e21de !important;
  border-radius: 8px !important;
  padding: 4px 8px !important;
  margin-left: 1rem !important;
  font-size: 10px !important;
  font-weight: bold !important;
`;

const Footer = ({
  row,
  col,
  colOne,
  colTwo,
  titleStyle,
  logoStyle,
  linkStyle,
  textEmailStyle,
}) => {
  return (
    <FooterWrapper>
      <CTASection padding={"4rem 4rem 0 4rem"} />
      <Container className="footer_container">
        <Box className="row" {...row}>
          <Box {...colOne}>
            <LogoWrapper to="/">
              <img
                src="https://storage.googleapis.com/brandflow-bucket/misc/neurons-logo.png"
                style={{ width: "100px" }}
              />
              {/* <VisualEyesLogo width={100} clipId="footer-logo" /> */}
            </LogoWrapper>

            {/* <Copyrights
              content={
                <span>
                  <p
                    style={{
                      color: "#212121",
                      fontSize: "14px",
                      display: "contents",
                    }}
                  >
                    Copyright © 2020 Loceye
                  </p>
                  {/* <Anchor
                    href="https://www.loceye.io"
                    target="_blank"
                    rel="noreferrer"
                    rel="noopener noreferrer"
                  >
                    Loceye
                  </Anchor>
                </span>
              }
            />
            <FooterSocial /> */}
          </Box>
          {/* End of footer logo column */}
          <Box {...colTwo}>
            {data.footer.map((widget, index) => (
              <Box className="col" {...col} key={`footer-widget-${index}`}>
                <ListHeader content={widget.title} {...titleStyle} />
                <List style={{ marginLeft: 0, paddingLeft: 0 }}>
                  {widget.menuItems.map((item, index) => (
                    <ListItem key={`footer-list-item-${index}`}>
                      {item.isAvailable ? (
                        item.target === "_blank" ? (
                          <span
                            onClick={() => {
                              if (item.url === APP_SIGN_UP_URL) {
                                fbTrack("track", "Lead");
                              }
                            }}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {item?.icon && (
                              <img
                                src={item?.icon}
                                style={{ width: "16px", marginRight: "8px" }}
                              />
                            )}

                            <a
                              id={`footer-${item.text.split(" ").join("-")}`}
                              href={item.url}
                              className="ListItem"
                              target={item.target}
                              rel="noreferrer"
                            >
                              {item.text}
                            </a>
                          </span>
                        ) : (
                          <Link
                            id={`footer-${item.text.split(" ").join("-")}`}
                            to={item.url}
                            className="ListItem"
                            target={item.target}
                          >
                            {item.text}
                          </Link>
                        )
                      ) : (
                        <span
                          className="ListItem"
                          id={`footer-${item.text.split(" ").join("-")}`}
                        >
                          {item.text} {!item.isAvailable && <Label>Soon</Label>}
                        </span>
                      )}
                    </ListItem>
                  ))}
                </List>
              </Box>
            ))}
          </Box>
          {/* End of footer List column */}
        </Box>
      </Container>
    </FooterWrapper>
  );
};

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object,
  titleStyle: PropTypes.object,
  linkStyle: PropTypes.object,
  logoStyle: PropTypes.object,
};

// Footer default style
Footer.defaultProps = {
  // Footer row default style
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-16px",
    mr: "-16px",
  },
  // Footer col one style
  colOne: {
    width: [1, "35%", "35%", "23%"],
    mt: [0, "13px"],
    mb: ["30px", 0],
    pl: ["16px", 0],
    pr: ["16px", "16px", 0],
  },
  // Footer col two style
  colTwo: {
    width: ["100%", "65%", "65%", "77%"],
    flexBox: true,
    flexWrap: "wrap",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  // Footer col default style
  col: {
    width: ["100%", "50%", "50%", "25%"],
    pl: "16px",
    pr: "16px",
    mb: "40px",
  },
  // widget title default style
  titleStyle: {
    color: "#343d48",
    fontSize: "20px",
    fontWeight: "700",
    mb: "16px",
  },
  // Default logo size
  logoStyle: {
    width: "100px",
    mb: "16px",
  },

  textEmailStyle: {
    color: "#212121",
    fontSize: "12px",
  },
};

export default Footer;
