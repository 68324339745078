import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import styled from "styled-components";
import { useCookies } from "react-cookie";
import { FiX } from "react-icons/fi";
import Image from "./Image";

const Container = styled.div`
  z-index: 100000000;
  position: fixed;
  bottom: 0px;
  right: 0px;
  max-width: 400px;
  margin: 0.5rem 1rem;

  & > div {
    width: 400px;
  }

  img {
    width: 100%;
    cursor: pointer;

    @media only screen and (max-width: 600px) {
      max-width: 100%;
      padding: 0 1rem;
    }
  }

  svg {
    position: absolute;
    top: 0;
    right: 0;
    margin: 1rem;

    color: white;
    cursor: pointer;

    @media only screen and (max-width: 600px) {
      right: 1rem;
    }
  }
`;

export const DiscountBanner = () => {
  const [cookies, setCookie] = useCookies(["christmasDiscount"]);
  const [isAccepted, setIsAccepted] = useState(true);

  useEffect(() => {
    if (cookies.christmasDiscount) {
      // console.log("exists");
    } else {
      setIsAccepted(false);
      // console.log("dont exists");
    }
  }, []);

  function initCookie() {
    setCookie("christmasDiscount", 1);
    setIsAccepted(true);
  }

  if (isAccepted) return null;

  return (
    <Container>
      <Image
        alt="Christmas Discount coupon"
        filename="/Christmas.png"
        onClick={() => {
          navigate("/pricing");
        }}
      />
      <FiX onClick={initCookie} />
    </Container>
  );
};

export default DiscountBanner;
