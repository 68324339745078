import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import Box from "reusecore/src/elements/Box";
import Image from "../../components/Image";
import Text from "reusecore/src/elements/Text";
import Heading from "reusecore/src/elements/Heading";
import Button from "reusecore/src/elements/Button";
import FeatureBlock from "../../components/FeatureBlock";
import Container from "../../components/Container";
import { fbTrack } from "../../utils/analytics";
import ContactFromWrapper, { SectionMainWrapper } from "./ctaSection.style";
import ClientsRow from "./ClientsRow";
import ClientsRowMobile from "./ClientsRowMobile";
import { FiArrowRight } from "react-icons/fi";
import useWindowSize from "../../hooks/useWindowSize";
import { APP_SIGN_UP_URL } from "../../utils/constants";
import redirect from "../../utils/redirect";

const CTASection = ({
  sectionWrapper,
  secTitleWrapper,
  button,
  titleStyle,
  descriptionStyle,
  title,
  description,
  cta,
  image,
  as,
  row,
  padding = "4rem",
}) => {
  const size = useWindowSize();

  return (
    <SectionMainWrapper padding={padding}>
      <Heading
        content={"Subscribe"}
        as="h2"
        className="text"
        style={{ opacity: 0, position: "absolute" }}
      />
      <Box {...sectionWrapper}>
        <Container className="containerClass" p={50}>
          <div>
            <Box {...secTitleWrapper}>
              <FeatureBlock
                title={
                  <Heading
                    content={
                      "Join 8000+ professionals and brands already using VisualEyes"
                    }
                    as="h3"
                    className="text"
                    {...titleStyle}
                  />
                }
                additionalContent={
                  size.width > 550 || true ? (
                    <ClientsRow width="100%" style={{ margin: "1.5rem 0" }} />
                  ) : (
                    <ClientsRowMobile
                      width="100%"
                      style={{ margin: "1rem 0" }}
                    />
                  )
                }
              />
            </Box>

            <Box className="button-wrapper">
              <span
                onClick={() => {
                  fbTrack("track", "Lead");
                }}
              >
                <Button
                  {...button}
                  id="btn-cta-element"
                  onClick={() =>
                    redirect(APP_SIGN_UP_URL, "Bottom CTA section")
                  }
                  style={{
                    minWidth: "100%",
                    background: "linear-gradient(90deg, #3E21de, #2174de)",
                  }}
                  title={
                    <div style={row}>
                      Become a member now{" "}
                      <FiArrowRight
                        size={16}
                        style={{ marginLeft: ".75rem" }}
                      />
                    </div>
                  }
                />
              </span>
            </Box>
          </div>
        </Container>
      </Box>
    </SectionMainWrapper>
  );
};

CTASection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  row: PropTypes.object,
  contactForm: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  button: PropTypes.object,
  note: PropTypes.object,
  titleStyle: PropTypes.object,
  descriptionStyle: PropTypes.object,
  colornote: PropTypes.object,
};

CTASection.defaultProps = {
  sectionWrapper: {
    id: "contact_section",
    as: "section",
    pt: ["40px", "80px", "80px", "80px"],
    pb: ["40px", "80px", "80px", "80px", "80px"],
  },
  secTitleWrapper: {
    mb: ["40px", "40px", "40px"],
    p: ["0 16px", 0, 0, 0, 0],
  },
  secText: {
    as: "span",
    display: "block",
    textAlign: "center",
    fontSize: `${2}`,
    letterSpacing: "0.15em",
    fontWeight: `${6}`,
    color: "primary",
    mb: `${3}`,
  },
  secHeading: {
    textAlign: "center",
    fontSize: [`${6}`, `${8}`],
    fontWeight: "400",
    color: "headingColor",
    letterSpacing: "-0.025em",
    mb: `${0}`,
  },
  row: {
    flexBox: true,
  },
  contactForm: {
    width: [1, 1, 1, 1],
  },
  button: {
    type: "button",
    fontSize: `${2}`,
    fontWeight: "600",
    borderRadius: "10px",
    pl: "22px",
    pr: "22px",
    colors: "primaryWithBg",
    height: `${4}`,
    width: "100%",
    boxShadow: "0 13px 40px -4px #2174de",
  },
  note: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#525f7f",
    lineHeight: "28px",
    mb: ["25px", "25px", "30px", "30px", "45px"],
    textAlign: ["center", "center"],
  },
  colornote: {
    fontSize: "16px",
    fontWeight: "500",
    color: "rgb(106, 82, 253)",
    lineHeight: "28px",
    mb: ["25px", "25px", "30px", "30px", "45px"],
    textAlign: ["center", "center"],
  },
  titleStyle: {
    fontSize: ["24px", "28px"],
    lineHeight: ["30px", "1.7"],
    fontWeight: "900",
    color: "#32325d",
    mb: "32px",
  },

  descriptionStyle: {
    fontSize: ["16px", "20px"],
    fontWeight: "400",
    color: "#525f7f",
    mb: ["25px", "25px", "30px", "30px", "45px"],
  },
  row: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default CTASection;
