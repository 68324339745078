import styled from "styled-components";
import Heading from "reusecore/src/elements/Heading";
import Text from "reusecore/src/elements/Text";
import { Link } from "gatsby";

const FooterWrapper = styled.footer`
  position: relative;
  background-color: #f9fbfd;
  background-color: #fff;
  overflow: hidden;
  background: linear-gradient(to bottom, #f7f8fd 0%, #fafbff 100%);

  ${"" /* @media (min-width: 576px) {
    padding-top: 200px;
    &:before {
      content: "";
      position: absolute;
      width: 104%;
      padding-bottom: 104%;
      border-top-right-radius: 11%;
      top: 14%;
      left: 0;
      pointer-events: none;
      background-color: #fff;
      transform: rotate(-6deg);
      @media (max-width: 767px) {
        padding-bottom: 150%;
      }
    }
  } */}

  .footer_container {
    background-repeat: no-repeat;
    background-position: center 50px;
    padding-top: 80px;
    padding-bottom: 80px;
    position: relative;
    @media (min-width: 576px) {
      background-image: url("https://d34cuim5v38p5e.cloudfront.net/visualeyes/footer-bg.webp");
    }
    @media (max-width: 990px) {
      padding-bottom: 20px;
    }
  }
`;

const List = styled.ul`
  @media (max-width: 575px) {
    text-align: center;
  }
`;

const ListHeader = styled(Heading)`
  @media (max-width: 575px) {
    text-align: center;
  }
`;

const Copyrights = styled(Text)`
  @media (max-width: 575px) {
    text-align: center;
  }
`;

const LogoWrapper = styled(Link)`
  @media (max-width: 575px) {
    display: block;
    display: flex;
  }
  & > svg {
    margin-bottom: 1rem;
  }
  @media (max-width: 575px) {
    & > svg {
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const ListItem = styled.li`
  @media (max-width: 575px) {
    text-align: center;
  }

  a,
  span {
    color: rgba(52, 61, 72, 0.8);
    font-size: 16px;
    line-height: 36px;
    transition: all 0.2s ease;
    &:hover:enabled,
    &:focus {
      outline: 0;
      text-decoration: none;
      color: #343d48;
    }
    &:hover {
      color: #3e21de;
    }
  }

  span {
    color: rgba(52, 61, 72, 0.5);
  }
`;

export { LogoWrapper, ListHeader, List, ListItem, Copyrights };

export default FooterWrapper;
