import Cookies from "universal-cookie";
import { gaLanded, gaConvert } from "./analytics";
import { emitter, experimentDebugger } from "@marvelapp/react-ab-test";

const cookies = new Cookies();

export const MEASURE_SUBSRIBE_RATE = "Measure Subscribe rate";
export const NAV_BAR_OPTIONS = "Nab Bar Options";
export const SIGNUP_PAGE = "Sign up illustrations";
export const PLUGINS_COLOR = "Available Plugins colors";
export const HERO_COPIES = "Hero section copies";
export const HERO_HEADER_IMAGE = "Hero section image vs lottie video";

function getCookieKey(experimentName) {
  switch (experimentName) {
    case MEASURE_SUBSRIBE_RATE:
      return "traffic";
    case PLUGINS_COLOR:
      return "plugins-colors";
    case NAV_BAR_OPTIONS:
      return "nav-options";
    case SIGNUP_PAGE:
      return "signup";
    case HERO_COPIES:
      return "hero-copies";
    case HERO_HEADER_IMAGE:
      return "hero-header-image";
    default:
      return "other";
  }
}

export function setup() {
  experimentDebugger.enable();

  emitter.addPlayListener((experimentName, variantName) => {
    // Check is session has already emitted an event
    const cookieKey = getCookieKey(experimentName);
    if (cookies.get(cookieKey) || cookies.get(cookieKey + "-convert")) {
      console.log(`[INFO]: Re-running experiment "${experimentName}"...`);
      return;
    } else {
      console.log(
        `[INFO]: First user's visit. Setting up the cookie "${cookieKey}"...`
      );
      cookies.set(cookieKey, "1");
      gaLanded(experimentName, variantName);
    }
  });

  // Called when a 'win' is emitted, in this case by this.experimentRef.current.win()
  emitter.addWinListener((experimentName, variantName) => {
    const cookieKey = getCookieKey(experimentName) + "-convert";
    if (cookies.get(cookieKey)) {
      console.log(`[INFO]: Re-converting experiment "${experimentName}"...`);
      return;
    } else {
      console.log(
        `[INFO]: First user's convert. Setting up the convert cookie "${cookieKey}"...`
      );
      cookies.set(cookieKey, "1");
      gaConvert(experimentName, variantName);
    }
  });
}
