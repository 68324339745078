import styled from "styled-components";

const ContactFromWrapper = styled.div`
  display: flex;
  align-items: stretch;
  width: auto;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4px;
  position: relative;
  @media (max-width: 990px) {
    padding: 0 20px;
  }
  @media (max-width: 575px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;
    button {
      width: 100%;
    }
  }

  .email_input {
    flex-grow: 1;
    @media (max-width: 575px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }
    &.is-material {
      &.is-focus {
        label {
          color: #aeb1b6;
          font-size: 12px;
        }
      }
    }

    input {
      height: 100%;
      background: #fff;
      font-size: 16px;
      font-weight: 500;
      color: #343d48;
      padding: 5px 16px;
      border-color: #dadada;
      border-style: solid;
      border-width: 2px;
      border-color: rgb(233, 227, 254, 0);
      border-radius: 4px;
      background-color: #f2f2f2;
      height: 64px;
      @media (max-width: 575px) {
        height: 60px;
      }
    }

    label {
      font-size: 16px;
      color: #343d48;
      font-weight: 500;
      padding-left: 10px;
      top: 5px;
    }
  }

  .field-wrapper {
    height: 100%;
  }
  button {
    position: absolute;
    right: 8px;
    border-radius: 4px;
    background-color: rgba(62, 33, 222);
    height: 48px;
    top: 8px;
    &:hover {
      ${"" /* background-color: rgba(62, 33, 222, 0.8); */}
      border-color: gb(106, 82, 253);
      opacity: 0.85;
    }
    @media (max-width: 990px) {
      right: 40px;
    }
    @media (max-width: 575px) {
      position: relative;
      right: 0;
      top: 0;
      height: 48px;
    }
  }
`;
const SectionMainWrapper = styled.div`
  position: relative;
  padding: ${p => p.padding};
  @media (max-width: 768px) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media (max-width: 480px) {
    padding-left: 16px;
    padding-right: 16px;
  }
  
  background: #F4F8FF;
  background: transparent;

  .containerClass {
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    padding: 4rem;
    @media (max-width: 768px) {
      padding: 3rem;
    }
    @media (max-width: 500px) {
      padding: 2rem;
    }

    position: relative;
    border-radius: 20px;
    background-color: rgb(255, 255, 255);
    color: white;
    box-shadow: 0px 0px 60px 6px rgba(0, 0, 0, 0.1);

    .button-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 768px) {
        justify-content: center;
        align-items: center;
      }
      @media (max-width: 500px) {
      width: 100%;
    }
    }
    
    .text {
        text-align: center !important;
    }
    .image {
      margin-right: 6rem;
      max-height: 300px;
      max-width: 300px
      width: 100%;

      @media (max-width: 768px) {
        margin-bottom: 1rem;
        margin-right: 0;
      }
    }
  }
`;

export { SectionMainWrapper };

export default ContactFromWrapper;
