import { APP_SIGN_UP_URL, APP_LOGIN_URL } from "../../utils/constants";

export default {
  footer: [
    // {
    //   title: "VisualEyes",
    //   menuItems: [
    //     // {
    //     //   url: APP_SIGN_UP_URL,
    //     //   text: "Create Account",
    //     //   target: "_blank",
    //     //   isAvailable: true,
    //     // },
    //     // {
    //     //   url: APP_LOGIN_URL,
    //     //   text: "Log In",
    //     //   target: "_blank",
    //     //   isAvailable: true,
    //     // },
    //     // {
    //     //   url: "/pricing",
    //     //   text: "Pricing",
    //     //   target: "_self",
    //     //   isAvailable: true,
    //     // },
    //     {
    //       url: "https://status.visualeyes.design",
    //       text: "Status",
    //       target: "_blank",
    //       isAvailable: true,
    //     },
    //   ],
    // },
    // {
    //   title: "Company",
    //   menuItems: [
    //     {
    //       url: "/about",
    //       text: "About",
    //       target: "_self",
    //       isAvailable: true,
    //     },

    //     {
    //       url: "/community",
    //       text: "Community",
    //       target: "_self",
    //       isAvailable: true,
    //     },
    //     {
    //       url: "/contact",
    //       text: "Contact",
    //       target: "_self",
    //       isAvailable: true,
    //     },
    //     {
    //       url:
    //         "https://www.notion.so/visualeyes/VisualEyes-Cheatsheet-9c30a263a02b4402ac841396b03bd382",
    //       text: "Learn",
    //       target: "_blank",
    //       isAvailable: true,
    //     },
    //   ],
    // },
    // {
    //   title: "Community",
    //   menuItems: [
    //     {
    //       url: "https://dribbble.com/VisualEyesHQ",
    //       text: "Dribbble",
    //       target: "_blank",
    //       isAvailable: true,
    //     },
    //     {
    //       url: "https://www.instagram.com/visualeyes_hq/",
    //       text: "Instagram",
    //       target: "_blank",
    //       isAvailable: true,
    //     },
    //     {
    //       url: "https://spectrum.chat/visualeyes-designers?tab=posts",
    //       text: "Spectrum",
    //       target: "_blank",
    //       isAvailable: true,
    //     },
    //     {
    //       url: "https://twitter.com/VisualEyesHQ",
    //       text: "Twitter",
    //       target: "_blank",
    //       isAvailable: true,
    //     },
    //   ],
    // },
    // {
    //   title: "Tools & Resources",
    //   menuItems: [
    //     {
    //       url: "/tools/above-the-fold",
    //       text: "Above the fold plugin",
    //       target: "_self",
    //       isAvailable: true,
    //     },
    //     {
    //       url: "https://blog.visualeyes.design/",
    //       text: "Blog",
    //       target: "_blank",
    //       isAvailable: true,
    //     },
    //     {
    //       url: "https://www.loceye.io/?ref=visualeyes",
    //       text: "Remote Eye-Tracking",
    //       target: "_blank",
    //       isAvailable: true,
    //     },
    //     {
    //       url: "/tools/use-cases",
    //       text: "Use Cases",
    //       target: "_self",
    //       isAvailable: true,
    //     },
    //   ],
    // },

    // {
    //   title: "Policy",
    //   menuItems: [
    //     {
    //       url:
    //         "https://d34cuim5v38p5e.cloudfront.net/legal/VisualEyes/Data-Protection-Policy.pdf",
    //       text: "Privacy",
    //       target: "_blank",
    //       isAvailable: true,
    //     },
    //     {
    //       url: "/security",
    //       text: "Security",
    //       target: "_self",
    //       isAvailable: true,
    //     },
    //   ],
    // },
    {
      title: "Platform",
      menuItems: [
        {
          url: "https://www.neuronsinc.com/predict",
          text: "Predict",
          target: "_blank",
          isAvailable: true,
          icon:
            "https://storage.googleapis.com/brandflow-bucket/misc/predict.png",
        },
        {
          url: "https://www.neuronsinc.com/explore",
          text: "Explore",
          target: "_blank",
          isAvailable: true,
          icon:
            "https://storage.googleapis.com/brandflow-bucket/misc/explore.png",
        },
        {
          url: "https://www.neuronsinc.com/api",
          text: "API",
          target: "_blank",
          isAvailable: true,
          icon: "https://storage.googleapis.com/brandflow-bucket/misc/api.png",
        },
      ],
    },
    {
      title: "Learn",
      menuItems: [
        {
          url: "https://www.neuronsinc.com/science",
          text: "Science",
          target: "_blank",
          isAvailable: true,
        },
        {
          url: "https://www.neuronsinc.com/insights",
          text: "Insights",
          target: "_blank",
          isAvailable: true,
        },
        {
          url: "https://www.neuronsinc.com/cases",
          text: "Customers",
          target: "_blank",
          isAvailable: true,
        },
      ],
    },
  ],
};
