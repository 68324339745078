import React, { Fragment, useRef, useEffect } from "react";
import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";
import { theme } from "../utils/theme";
import { ResetCSS } from "../utils/style/ResetCSS";
import { GlobalStyle, ContentWrapper } from "../containers/saas.style";
import Navbar from "../containers/Navbar";
import Footer from "../containers/Footer";
import { DrawerProvider } from "../contexts/DrawerContext";
import SEO from "../components/SEO/SEO";
import PolicyLabel from "../components/PolicyPopup";
import { CookiesProvider } from "react-cookie";
import { useCookies } from "react-cookie";
import { setup } from "../utils/abTesting";
import DiscountBanner from "./DiscountBanner";
import Announcement from "react-announcement";
import Confetti from "../../assets/svg/confetti.svg";
import { gaInit } from "../utils/analytics";
import NeuronsBanner from "./NeuronsBanner";

// gaInit();
setup();

const MainLayout = ({
  title,
  description,
  children,
  hasGradient,
  innerZ,
  pathname,
  tool,
}) => {
  const [cookies, setCookie] = useCookies(["traffic", "convert"]);

  useEffect(() => {
    if (cookies.traffic || cookies.convert) {
      console.log("[INFO]: Analytics event won't be send");
    } else {
      setCookie("traffic", 1);
    }
  }, []);

  // if (typeof window !== "undefined") {
  //   window.onblur = () => {
  //     document.title = "Miss you already...";
  //   };
  //   window.onfocus = () => {
  //     document.title = PAGE_TITLE;
  //   };
  // }

  return (
    <CookiesProvider>
      <ThemeProvider theme={theme}>
        <Fragment>
          <link
            href="https://fonts.googleapis.com/css?family=Yantramanav:400,500,700,900&display=swap"
            rel="stylesheet"
          />
          <SEO
            title={title}
            description={description}
            pathname={pathname}
            tool={tool}
          />
          <ResetCSS />
          <GlobalStyle />
          <ContentWrapper
            style={{
              backgroundColor: "#fff",
              backgroundImage: hasGradient
                ? "linear-gradient(white, #F8F9FE, #F8F9FE)"
                : "",
            }}
          >
            {/* <NeuronsBanner /> */}

            <Sticky
              top={0}
              innerZ={innerZ || 9999}
              activeClass="sticky-nav-active"
            >
              <DrawerProvider>
                <Navbar />
              </DrawerProvider>
            </Sticky>

            <PolicyLabel />
            {/* <Announcement
              title="🎉 Awesome Updates"
              subtitle="We gladly announce the launch of our web platform. Learn more by clicking this message."
              link="https://www.notion.so/Changelog-5c6188d8774a4711af4d55030102aea4"
              imageSource={Confetti}
              daysToLive={30}
              secondsBeforeBannerShows={2}
              closeIconSize={16}
            /> */}

            {/* Spacer */}
            <div
              style={{
                height: "4rem",
                background: "transparent",
                width: "100%",
              }}
            />

            {children}
            <Footer />
          </ContentWrapper>
        </Fragment>
      </ThemeProvider>
    </CookiesProvider>
  );
};

export default MainLayout;
